<template>
  <div class="uf-approvalResult">
    <div class="inner">
      <div class="icon">
        <van-icon name="success" color="#fff" />
      </div>
      <div class="result">购买成功</div>
      <div class="desc">施工申请审批已发起，等待审核</div>
      <div class="button">
        <div
          class="link"
          @click="
            $router.push({
              name: $RouterName.HOME
            })
          "
        >
          返回首页
        </div>
        <div
          class="link"
          @click="
            $router.push({
              name: $RouterName.APPROVAL_DETAIL,
              params: {
                id: $route.query.detailId
              }
            })
          "
        >
          查看审批详情
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Result"
};
</script>

<style lang="less" scoped>
.uf-approvalResult {
  min-height: 100vh;
  background-color: #f6f7fa;

  .inner {
    background: #fff;
    height: 220px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      background: #1ed278;
    }

    .result {
      margin-top: 20px;
      font-size: 17px;
      color: #2d333e;
      line-height: 20px;
    }

    .desc {
      font-size: 14px;
      color: #828892;
      margin-top: 10px;
      text-align: center;
      line-height: 15px;
    }

    .link {
      font-size: 14px;
      color: #0065ff;
      line-height: 15px;
      margin: 15px 10px 0;
    }
    .button {
      display: flex;
    }
  }
}
</style>
